import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    Input,
    TemplateRef,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentFactory,
    ComponentRef,
    OnDestroy,
    ElementRef,
    forwardRef,
    OnChanges,
    SimpleChanges,
    EventEmitter, Output
} from '@angular/core';
import {FormsinglefileComponent, IFileObj, isIFileObject} from './formsinglefile/formsinglefile.component';
import {FormGroup, FormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl} from '@angular/forms';
import {AdminformComponent} from 'src/app/admin/controllers/adminform/adminform.component';
import {AdmingridService} from 'src/app/admin/services/admingrid.service';
import {Router, ActivatedRoute} from '@angular/router';
import {MessageService} from 'src/app/admin/services/message.service';
import {PermissionsService} from 'src/app/admin/services/permissions.service';
import {SystemtextService} from 'src/app/services/systemtext.service';
import {isArray, isObject, isString} from 'util';
import {environment} from 'src/environments/environment';

// For more information see CUSTOM FORM CONTROLS IN ANGULAR
// https://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html

/**
 * Returns a validator for the FileUpload compoment.
 * @param required Minimum number of files required.
 */
export function createFileUploadRequiredValidator(required: number) {
    let err = {required: required};

    return function validateFileUpload(c: FormControl) {
        return required && (!c.value || c.value['fileCount'] < required) ? err : null;
    };
}

export type FileDef = {
    filename: string;
    data: string;
};

export const FileUploadFileType = {
    /** JPG, JPEG, PNG, GIF, SVG */
    images: 'image/jpeg, image/png, image/gif, image/svg',

    pngAndJpg: '.jpg, .png',

    video: 'video/mp4',

    /** Only arc **/
    ar: '.arc',

    /** Only dat **/
    dat: '.dat',

    /** Only xml **/
    xml: 'text/xml',

    /** Only png **/
    png: 'image/png',

    /** PDF */
    pdf: 'application/pdf',

    /** DOC, DOCX, ODT, PDF */
    documents: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, application/pdf',
};


@Component({
    selector: 'app-formfileupload',
    templateUrl: './formfileupload.component.html',
    styleUrls: ['./formfileupload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormfileuploadComponent),
            multi: true
        }
        // TODO: Működő módon implementálni az "@Input() required"-del együtt!
        // ,
        // {
        //     provide: NG_VALIDATORS,
        //     useValue: forwardRef(() => FormfileuploadComponent),
        //     multi: true
        //   }
    ]
})
export class FormfileuploadComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, OnChanges {
    @Input() parentFormGroup: FormGroup;
    @Input() title: string;
    @Input() browseButtonTitle: string;
    @Input() maxFileCount: number;
    @Input() maxFileSize: number;
    @Input() toolTipOptions: any;
    @Input() actionInProgress;
    @Input() readonlyProp;
    protected _acceptStr: string;
    protected _accept: string[] = [];

    public fileControls: ComponentRef<FormsinglefileComponent>[] = [];
    protected removedFiles: (string | IFileObj)[] = [];
    protected keptFiles: (string | IFileObj)[] = [];

    protected fileAreaCaption: string;
    protected defaultBrowseButtonTitle: string;
    protected hasDragDropSupport: boolean;
    protected isDragOver: boolean;

    protected fileCountLimitMessage: string = this.sts.c('form.file_limit');
    protected fileSizeLimitMessage: string = this.sts.c('form.files_exceed_size_limit');
    protected fileTypeRestrictionMessage: string = this.sts.c('form.file_type_restriction');
    protected sizeLimitMessage: string = this.sts.c('form.files_size_limit');
    protected requiredFileCountMessage: string = this.sts.c('form.required_file_count');

    validateFn: Function;

    /**
     * The comma separated list of accepted MIME types or one of the FileUploadFileType values.
     * Eg. 'audio/aac, audio/mpeg', or 'images'
     */
    @Input() set accept(value: string) {
        let v = FileUploadFileType.hasOwnProperty(value) ? FileUploadFileType[value] : value;
        this._acceptStr = v;
        this._accept = v ? v.split(/, */) : [];
    }

    get accept() {
        return this._acceptStr;
    }

    @Input() acceptRestrictionMessage: string;
    @Input() readonly: boolean;
    // TODO: Működő módon implementálni a "provide: NG_VALIDATORS" résszel együtt!
    @Input() required: boolean;
    @Input() control: FormControl;

    @ViewChild('singleFileIntputTemplate', {read: ViewContainerRef, static: true}) container: ViewContainerRef;
    @ViewChild('fileinput') protected fileinput: ElementRef;

    @Output() sendDestroyRequest = new EventEmitter();
    @Output() sendSetThumbnailIconByMimeTypeRequest = new EventEmitter();

    get fileCount(): number {
        return this.fileControls.length;
    }

    protected propagateChangeCallback = (_: any) => {
    }

    constructor(protected resolver: ComponentFactoryResolver, protected sts: SystemtextService) {
    }

    ngOnInit() {

        
        this.hasDragDropSupport = this.checkDragDropSupport();
        let stsLabel = this.hasDragDropSupport ? 'form.click_or_drag_file_to_select' : 'form.click_to_select_file';
        this.fileAreaCaption = this.sts.c(stsLabel);
        
        this.defaultBrowseButtonTitle = this.sts.c('form.browse');
        
        // TODO: Működő módon implementálni az "@Input() required"-del együtt!
        // this.validateFn = createFileUploadRequiredValidator(this.required);
        this.control.validator = createFileUploadRequiredValidator(this.required?1:0);
    }

    ngAfterViewInit() {
        // console.log('ngAfterViewInit', this.container);
    }

    protected checkDragDropSupport(): boolean {
        let div = document.createElement('div');
        return ('draggable' in div) || ('ondragstart' in div && 'ondrop' in div);
    }

    protected onFileChange(event: Event) {
        // console.log('onFileChange', event);
        let files = event.target['files'] as FileList;

        if (!files.length) return;

        this.addFiles(files);

        event.target['value'] = '';
    }

    protected addFiles(files: FileList) {
        if (this.maxFileCount != undefined && files.length + this.fileControls.length > this.maxFileCount) {
            alert(this.fileCountLimitMessage.replace(/:max_file_count/, `${this.maxFileCount}`));
            return;
        }

        for (const index in files) {
            if (files.hasOwnProperty(index)) {
                const file = files[index];
                const extension = "."+file.name.split(".").pop().toLowerCase();
                if (this._accept.length && (this._accept.indexOf(file.type) < 0 && this._accept.indexOf(extension) < 0) && file.type != '') {
                    //console.log(this._accept.length, this._accept);
                    //console.log(file.type);

                    var message = this.acceptRestrictionMessage || this.fileTypeRestrictionMessage;
                    alert(message.replace(/:accepted_file_types/, `${this._accept.join(', ')}`));
                    return;
                }
                if (this.maxFileSize && file.size > this.maxFileSize * 1024 * 1024) {
                    alert(this.fileSizeLimitMessage.replace(/:max_file_size/, `${this.maxFileSize}`));
                    return;
                }
            }
        }

        for (const index in files) {
            if (files.hasOwnProperty(index)) {
                const file = files[index];
                this.createFileElement(file);
            }
        }

        this.propagateChange();
    }

    protected createFileElement(src: string | File | IFileObj) {
        const factory: ComponentFactory<FormsinglefileComponent> = this.resolver.resolveComponentFactory(FormsinglefileComponent);
        let componentRef: ComponentRef<FormsinglefileComponent> = this.container.createComponent(factory);
        this.fileControls.push(componentRef);

        if (src instanceof File) {
            componentRef.instance.file = src as File;
        } else if (isIFileObject(src)) {
            this.keptFiles.push(src);
            componentRef.instance.fileObj = <IFileObj>src;
        } else if (isString(src)) {
            this.keptFiles.push(src);
            componentRef.instance.url = src;
        } else {
            throw new Error('Src must be of File or string type.');
        }

        componentRef.instance.readonly = this.readonly;
        componentRef.changeDetectorRef.detectChanges();
        componentRef.instance.destroyRequest.subscribe(() => this.destroyRequest(componentRef));
        componentRef.instance.setThumbnailIconByMimeTypeRequest.subscribe(value => this.sendSetThumbnailIconByMimeTypeRequest.emit(value));
    }


    protected handled(event: Event) {
        event.preventDefault();
        event.cancelBubble = true;
    }

    protected onBrowse(event: MouseEvent) {
        // console.log('onBrowse', event);
        this.handled(event);
        this.fileinput.nativeElement.click();
    }

    protected onDragOver(event: DragEvent) {
        // console.log('onDragOver', event);
        this.handled(event);
        this.isDragOver = true;
    }

    protected onDragOut(event: DragEvent) {
        // console.log('onDragOut', event);
        this.handled(event);
        this.isDragOver = false;
    }

    protected onDrop(event: DragEvent) {
        // console.log('onDrop', event);
        this.handled(event);
        this.isDragOver = false;
        let droppedFiles = event.dataTransfer.files;
        this.addFiles(droppedFiles);
    }

    protected propagateChange() {
        // console.log('propagateChange');

        let data = {
            type: 'formfileupload',
            keptFiles: this.keptFiles,
            addedFiles: this.getFiles(),
            removedFiles: this.removedFiles,
            fileCount: this.fileControls.length,
        };

        this.propagateChangeCallback(data);


    }

    getFiles(): File[] {
        let files: File[] = [];
        this.fileControls.forEach(c => {
            if (c.instance.file) files.push(c.instance.file);
        });
        return files;
    }

    writeValue(fileObj: any): void {
        this.destroyFileInputControls();
        if (!fileObj) return;
        if (isArray(fileObj))
            (<any[]>fileObj).forEach(file => this.createFileElement(file));
        else
            this.createFileElement(fileObj);

        this.propagateChange();
    }

    registerOnChange(fn: any): void {
        this.propagateChangeCallback = fn;
        this.propagateChange();
    }

    registerOnTouched(_: any): void {
        // Unused, but required.
    }

    setDisabledState?(isDisabled: boolean): void {
        this.readonly = isDisabled;
    }

    validate(c: FormControl) {
        return this.validateFn(c);
    }

    getErrorMessage() {
        return this.requiredFileCountMessage.replace(/:min_file_count/, `${this.control.errors.required}`);
    }

    getSizeLimitMessage() {
        return this.sizeLimitMessage.replace(/:max_file_size/, `${this.maxFileSize}`);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // TODO: Működő módon implementálni az "@Input() required"-del együtt!
        // if(changes.required) {
        //     this.validateFn = createFileUploadRequiredValidator(this.required);
        // }
    }

    protected destroyRequest(componentRef: ComponentRef<FormsinglefileComponent>) {
        this.destroyFileInputElement(componentRef);
        this.propagateChange();
        this.sendDestroyRequest.emit(componentRef);
    }

    protected destroyFileInputElement(componentRef: ComponentRef<FormsinglefileComponent>) {
        let index = this ? this.fileControls.indexOf(componentRef) : -1;
        if (index >= 0) this.fileControls.splice(index, 1);

        // NOTE: Instances of File class should not be added to the removedFiles array,
        // because they were added during the current edit session.
        var value = componentRef.instance.fileObj || componentRef.instance.url;
        if (value) {
            this.keptFiles = this.keptFiles.filter(item => item != value);
            this.removedFiles.push(value);
        }

        componentRef.changeDetectorRef.detach();
        componentRef.destroy();
    }

    protected destroyFileInputControls() {
        this.fileControls.forEach((componentRef) => this.destroyFileInputElement(componentRef));
    }

    ngOnDestroy() {
        this.destroyFileInputControls();
    }

}
