import {  Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AdminformComponent} from '../../controllers/adminform/adminform.component';
import {FormControl, Validators} from '@angular/forms';
import KeycodesCreationStatus from '../../models/KeycodesCreationStatus'
import { QrCodeComponent } from '../qr-code/qr-code.component';

@Component({
  selector: 'app-key-codes-creation-form',
  templateUrl: './key-codes-creation-form.component.html',
  styleUrls: ['./key-codes-creation-form.component.scss']
})
export class KeyCodesCreationFormComponent extends AdminformComponent implements OnInit, OnDestroy {

  @ViewChild('qrCode') qrCode;

  selectedQrLogo:any;

  id: any;
  params: any;

  booksItemList = [];
  booksSettings = {};

  qrlogosItemList = [];
  qrlogosSettings = {};

  quantityControl: FormControl;
  usesControl: FormControl;
  bookControl: FormControl;
  qrurlControl: FormControl;
  commentControl: FormControl;
  qrlogoControl: FormControl;

  fontColor: FormControl;
  fontColorDefaultValue = "#000000";
  firstColor: FormControl;
  firstColorDefaultValue = "#000000";
  secondColor: FormControl;
  secondColorDefaultValue = "#000000";

  gradientItemList = [
    {id:1, name:'no'},
    {id:2, name:'vertical'},
    {id:3, name:'horizontal'},
    {id:4, name:'diagonal'},
    {id:5, name:'inverse_diagonal'},
    {id:6, name:'radial'}
  ]
  gradientSettings = {};
  gradientControl: FormControl;

  sizeControl: FormControl;
  defaultSizeValue = 100;

  defaultUsesValue = 25;

  statusControl: FormControl;
  statusItemList;
  statusSettings = {};

  newStyleControl: FormControl;


  isUpdate = false;
  kcId;
  showQuantityAlert = false;
  showQuantityAlertText = "";

  showStyleInputs = true;
  styleInputsInfo = "";

  useBookKeyControl: FormControl;
  showUniqueKeyInputs = true;

  @ViewChild(QrCodeComponent) qrPreview;
  ngOnInit(): void {


    this.tableName = 'keycodescreation';
    this.formName = 'keycodescreation';
    this.setPermissions(this.tableName);
    this.sts.st = {form: this.localeText};

    this.isUpdate = this.formType === "update" ? true : false;

    this.statusItemList = JSON.parse(JSON.stringify(KeycodesCreationStatus))

    this.permissions = this.permissionsService.getPermissions("books");

    this.qrlogosSettings = {
      text: 'Select qrlogo',
      classes: 'qrlogos-list',
      enableSearchFilter: true,
      singleSelection: true,
      enableFilterSelectAll: false,
      emitOutput: true,
      disabled: !this.permissions.Edit
    };

    this.booksSettings = {
      text: 'Select book',
      classes: 'books-list',
      enableSearchFilter: true,
      singleSelection: true,
      enableFilterSelectAll: false,
      disabled: !this.permissions.Edit
    };
    this.statusSettings = {
      text: 'Select status',
      classes: 'status-list',
      enableSearchFilter: true,
      singleSelection: true,
      enableFilterSelectAll: false,
      disabled: !this.permissions.Edit
    };

    this.gradientSettings = {
      text: 'Select gradient',
      classes: 'status-list',
      enableSearchFilter: true,
      singleSelection: true,
      enableFilterSelectAll: false,
      disabled: !this.permissions.Edit
    };


    // Update
    this.params = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];

      if (this.id) {
        this.kcId = this.id;
        this.admingridService.getItem(this.tableName, this.id).subscribe(data => {

          // console.log('data', data);

          //this.sts.st = data.st;
          this.sts.st = Object.assign({}, this.sts.st, data.st);

          this.showQuantityAlertText = this.sts.st.keycodescreation.quantity_alert;
          this.styleInputsInfo = this.sts.st.keycodescreation.change_the_qr_design_info;

          this.replaceSystemTitle('keycodescreation.keycodescreation');
          this.replaceTexts();

          this.mainForm.controls['qrurl'].setValue(data['item']['qrurl']);
          this.mainForm.controls['quantity'].setValue(data['item']['quantity']);
          this.mainForm.controls['uses'].setValue(data['item']['uses']);
          this.mainForm.controls['comment'].setValue(data['item']['comment']);
          this.mainForm.controls['fontColor'].setValue(data['item']['font_color']);
          this.mainForm.controls['firstColor'].setValue(data['item']['first_color']);
          this.mainForm.controls['secondColor'].setValue(data['item']['second_color']);
          this.mainForm.controls['size'].setValue(data['item']['size']);

          this.createItemList(data.books, data['item'], 'books', 'book');
          this.createItemList(this.statusItemList, data['item']['status'], 'status', 'status');
          this.createItemList(this.gradientItemList, data['item']['gradient'], 'gradient', 'gradient');
          this.createItemList(data.qrlogos, data.item, 'qrlogos', 'qrlogo');
          this.selectedQrLogo = this.mainForm.get('qrlogo').value[0].id;
          this.formReadyToShow = true;

        }, error => {
          console.log(<any>error);
          var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
          this.messageService.sendMessage({text: text, type: 'danger'});
        });
      }

      this.quantityControl = this.fb.control('', [Validators.required, Validators.pattern("^[0-9]*$"),  Validators.max(5000)]);
      this.usesControl = this.fb.control('', [Validators.required, Validators.pattern("^[0-9]*$")]);
      this.bookControl = this.fb.control('', [Validators.required]);
      this.qrurlControl = this.fb.control('', [Validators.required]);
      this.qrlogoControl = this.fb.control('', [Validators.required]);
      this.statusControl = this.fb.control('', [Validators.required]);
      this.commentControl = this.fb.control();
      this.fontColor = this.fb.control('', [Validators.required]);
      this.firstColor = this.fb.control('', [Validators.required]);
      this.secondColor = this.fb.control('', [Validators.required]);
      this.gradientControl = this.fb.control('', [Validators.required]);
      this.sizeControl = this.fb.control('', [Validators.required]);
      this.newStyleControl = this.fb.control('');
      this.useBookKeyControl = this.fb.control('');

      this.mainForm = this.fb.group({
        id: this.kcId,
        quantity: this.quantityControl,
        uses: this.usesControl,
        book: this.bookControl,
        qrurl: this.qrurlControl,
        qrlogo: this.qrlogoControl,
        status: this.statusControl,
        fontColor: this.fontColor,
        firstColor: this.firstColor,
        secondColor: this.secondColor,
        gradient: this.gradientControl,
        size: this.sizeControl,
        comment: this.commentControl,
        newStyle: this.newStyleControl,
        useBookKey: this.useBookKeyControl,
      });

      if (this.formType == 'create') {
        this.mainForm.controls['uses'].setValue(this.defaultUsesValue);
        this.mainForm.controls['size'].setValue(this.defaultSizeValue);
        this.mainForm.controls['fontColor'].setValue(this.fontColorDefaultValue);
        this.mainForm.controls['firstColor'].setValue(this.firstColorDefaultValue);
        this.mainForm.controls['secondColor'].setValue(this.secondColorDefaultValue);
      }

      if (this.isUpdate) {
        this.showStyleInputs = false
      }

    });

    // Create
    if (this.formType == 'create') {
      this.admingridService.createItem(this.tableName).subscribe(data => {
        this.sts.st = Object.assign({}, this.sts.st, data.st);
        this.showQuantityAlertText = this.sts.st.keycodescreation.quantity_alert;
        this.replaceSystemTitle('keycodescreation.keycodescreation');
        this.replaceTexts();
        this.createItemList(data.books, false, 'books', 'books');
        this.createItemList(this.statusItemList, 1, 'status', 'status');
        this.createItemList(this.gradientItemList, 1, 'gradient', 'gradient');
        this.createItemList(data.qrlogos, data, 'qrlogos', 'qrlogo');
        this.selectedQrLogo = this.mainForm.get('qrlogo').value[0]?.id

        this.mainForm.controls['qrurl'].setValue(data.qrurl);
        this.formReadyToShow = true;
      }, error => {
        console.log(error);
        var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
        this.messageService.sendMessage({text: text, type: 'danger'});
      });
    }

    this.mainForm.get("quantity").valueChanges.subscribe(x => {
      this.showQuantityAlert = (this.formType != 'update') && x > 999 && x < 5001
    })

    this.mainForm.get("newStyle").valueChanges.subscribe(val => {
      this.showStyleInputs = val
      this.showQuantityAlert = this.mainForm.get("quantity").value > 999
    })
    this.mainForm.get("useBookKey").valueChanges.subscribe(val => {
      this.showUniqueKeyInputs = !val
      if(this.showUniqueKeyInputs) {
        this.mainForm.get('quantity').setValidators([Validators.required, Validators.pattern("^[0-9]*$"),  Validators.max(5000)])
        this.mainForm.get('uses').setValidators([Validators.required, Validators.pattern("^[0-9]*$")])
      } else {
        this.mainForm.get('quantity').clearValidators();
        this.mainForm.get('uses').clearValidators();
      }
      this.mainForm.get('quantity').updateValueAndValidity();
      this.mainForm.get('uses').updateValueAndValidity();

    })
  }



  createItemList(items, selectedItems, listname, controlname) {
    var itemListArray = [];

    if(controlname === "qrlogo") {
      items.forEach((item) => {
        itemListArray.push({'id': item.id, 'itemName': item.name, 'image': item.image});
      });
    } else {
      items.forEach((item) => {
        itemListArray.push({'id': item.id, 'itemName': item.name});
      });
    }


    this[listname + 'ItemList'] = itemListArray;

    if (selectedItems) {
      const selectedItemListArray = [];
      if(controlname === "book") {
        items.forEach((item) => {
          if(item.id === selectedItems.book_id ) {
            selectedItemListArray.push({'id': item.id, 'itemName': item.name});
          }
        });
      }
      if(controlname === "status" || controlname === "gradient") {
        items.forEach((item) => {
          if(item.id === selectedItems ) {
            selectedItemListArray.push({'id': item.id, 'itemName': item.name});
          }
        });
      }
      if(controlname === "qrlogo") {
        items.forEach((item) => {
          if(item.id == selectedItems.qrlogoid ) {
            selectedItemListArray.push({'id': item.id, 'itemName': item.name, 'image': item.image});
          }
        });
      }
      this.mainForm.controls[controlname].setValue(selectedItemListArray);

    }
  }

  replaceTexts() {
    this.sts.replace('form.select_item', [{'find': ':item', 'replace': 'books.book'}]);
    this.booksSettings['text'] = this.sts.c('form.select_item');
    this.booksSettings['searchPlaceholderText'] = this.sts.c('form.search');

    var book = this.sts.c('books.book');
    this.sts.replace('form.select_item', [{'find': book, 'replace': 'keycodescreation.status'}]);
    this.statusSettings['text'] = this.sts.c('form.select_item');
    this.statusSettings['searchPlaceholderText'] = this.sts.c('form.search');
    this.statusItemList.forEach((item) => {
      item.name = this.sts.c(`keycodescreation.statuses.${item.name}`)
    });

    var status = this.sts.c('keycodescreation.status');
    this.sts.replace('form.select_item', [{'find': status, 'replace': 'keycodescreation.gradient_title'}]);
    this.gradientSettings['text'] = this.sts.c('form.select_item');
    this.gradientSettings['searchPlaceholderText'] = this.sts.c('form.search');
    this.gradientItemList.forEach((item) => {
      item.name = this.sts.c(`keycodescreation.gradient.${item.name}`)
    });

    this.qrlogosSettings['text'] = this.sts.c('keycodescreation.qrlogo');
    this.qrlogosSettings['searchPlaceholderText'] = this.sts.c('form.search');
  }

  public qrLogoSelect(e) {
    this.qrCode.setLogo(e.id);
    this.selectedQrLogo = this.mainForm.get('qrlogo').value[0].id;
  }

  public qrLogoDeselect(e) {
    this.qrCode.setLogo(0);
    this.selectedQrLogo = 0;
  }

  ngOnDestroy() {
    this.params.unsubscribe();
  }


}
