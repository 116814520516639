import { FormControl, FormGroup } from "@angular/forms"

export interface Book{
    id:string
    name:string
    uid:string
    description:string
    language:string
    category:string
    parent_id:string
    organization_id:string
    marketplace:string
    shopping_id:string
    is_shopping:boolean
}
export type ArType = 'ar'|'video'|'group'|'gc_video'|'gc_ar'
export const AR_FILE_TYPES = [
  'arAndroid',
  'arIos',
  'arTargetImage',
  'arWebgl',
  'arCoverImage',
  'video',
  'videoTargetImage',
  'videoCoverImage',
  'groupTargetImage',
];

export type TemplateContext ={
  parentFormGroup:FormGroup;
  child:FormGroup;
  sts:any;
  index:number;
  destroy:any
}
