import {Routes} from '@angular/router';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {UsersComponent} from './pages/users/users.component';
import {BooksComponent} from './pages/books/books.component';
import {BookformComponent} from './pages/books/bookform.component';
import {OrdersComponent} from './pages/orders/orders.component';
import {ObjectsComponent} from './pages/objects/objects.component';
import {ProductsComponent} from './pages/products/products.component';
import {RolesComponent} from './pages/roles/roles.component';
import {UserformComponent} from './pages/users/userform.component';
import {RoleformComponent} from './pages/roles/roleform.component';
import {OrganizationsComponent} from './pages/organizations/organizations.component';
import {OrganizationformComponent} from './pages/organizations/organizationform.component';
import {SystemeventsComponent} from './pages/systemevents/systemevents.component';
import {SystemmessageComponent} from './pages/systemmessage/systemmessage.component';
import {MarketplaceComponent} from './pages/marketplace/marketplace.component';
import {ShoppingsComponent} from './pages/shoppings/shoppings.component';
import {ShoppingformComponent} from './pages/shoppings/shoppingform.component';
import { ArEditorComponent } from './pages/ar-editor/ar-editor.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { PagenotfoundComponent } from '../pages/pagenotfound/pagenotfound.component';
import {LanguagecheckComponent} from "./pages/languagecheck/languagecheck.component";
import {KeyCodesCreationComponent} from "./pages/key-codes-creation/key-codes-creation.component";
import {KeyCodesCreationFormComponent} from "./pages/key-codes-creation/key-codes-creation-form.component";
import {KeyCodesComponent} from "./pages/key-codes/key-codes.component";
import {KeyCodesFormComponent} from "./pages/key-codes/key-codes-form.component";
import {ClientStatisticsComponent} from "./pages/client-statistics/client-statistics.component";

export const adminRoutes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
    {path: 'dashboard', component: DashboardComponent},

    {
        path: 'systemevents', children: [
            {path: 'show', component: SystemeventsComponent},
        ]
    },
    {
        path: 'clientstatistics', children: [
            {path: 'show', component: ClientStatisticsComponent},
        ]
    },
    {
      path: 'systemmessage', children: [
        {path: 'edit', component: SystemmessageComponent},
      ]
    },
    {
        path: 'users', children: [
            {path: 'show', component: UsersComponent},
            {path: 'create', component: UserformComponent},
            {path: 'edit/:id', component: UserformComponent},
            {path: 'show/:id', component: UserformComponent},
            {path: 'copy/:id', component: UserformComponent},
        ]
    },
    {
        path: 'roles', children: [
            {path: 'show', component: RolesComponent},
            {path: 'create', component: RoleformComponent},
            {path: 'edit/:id', component: RoleformComponent},
            {path: 'show/:id', component: RoleformComponent},
            {path: 'copy/:id', component: RoleformComponent},
        ]
    },
    {
        path: 'organizations', children: [
            {path: 'show', component: OrganizationsComponent},
            {path: 'create', component: OrganizationformComponent},
            {path: 'edit/:id', component: OrganizationformComponent},
            {path: 'show/:id', component: OrganizationformComponent},
            {path: 'copy/:id', component: OrganizationformComponent},
        ]
    },

    {
        path: 'books', children: [
            {path: 'show', component: BooksComponent},
            {path: 'create', component: BookformComponent},
            {path: 'edit/:id', component: BookformComponent},
            {path: 'show/:id', component: BookformComponent},
            {path: 'copy/:id', component: BookformComponent},
        ]
    },

    {
        path: 'keycodescreation', children: [
            {path: 'show', component: KeyCodesCreationComponent},
            {path: 'create', component: KeyCodesCreationFormComponent},
            {path: 'edit/:id', component: KeyCodesCreationFormComponent},
            {path: 'show/:id', component: KeyCodesCreationFormComponent},
            {path: 'copy/:id', component: KeyCodesCreationFormComponent},
        ]
    },
    {
        path: 'keycodes', children: [
            {path: 'show', component: KeyCodesComponent},
        {path: 'edit/:id', component: KeyCodesFormComponent},
        ]
    },

    {path: 'marketplace', component: MarketplaceComponent},
    {path: 'languagecheck', component: LanguagecheckComponent},

    {
        path: 'shoppings', children: [
            {path: 'show', component: ShoppingsComponent},
            {path: 'create', component: ShoppingformComponent},
        ]
    },

    {path: 'orders', component: OrdersComponent},
    {path: 'objects', component: ObjectsComponent},
    {path: 'products', component: ProductsComponent},
    {path: 'areditor', component: ArEditorComponent, canDeactivate: [CanDeactivateGuard]},
    {path: '**', component: PagenotfoundComponent},
];
