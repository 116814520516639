<div class="preloader" *ngIf="!isDataLoaded">
  <ngx-loading-bar [includeSpinner]="false" [includeBar]="true" [fixed]="false" ></ngx-loading-bar>
</div>
<div class="container-fluid"  *ngIf="isDataLoaded">
  <div class="row">
    <div *ngIf="isFlagsLoaded">
      <app-menutop></app-menutop>
    </div>

    <main class="content">
      <ngx-loading-bar [includeSpinner]="false" [includeBar]="true" [fixed]="false" *ngIf="isDataLoaded"></ngx-loading-bar>

      <router-outlet></router-outlet>

    </main>
  </div>
</div>
