import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {AdminComponent} from './admin/admin.component';
import {adminRoutes} from './admin/admin.routes';
import {AuthGuardService} from './services/auth-guard.service';
import {webRoutes} from './web.routes';
import {WebComponent} from './web.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';

const appRoutes: Routes = [
    {path: '', component: WebComponent, children: webRoutes},
    {path: 'admin', component: AdminComponent, children: adminRoutes, canActivate: [AuthGuardService]},
    {path: '404', component: PagenotfoundComponent},
    {path: '**', redirectTo: '404'}
];

export const routes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', useHash: true});
