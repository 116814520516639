export const environment = {
	title: 'TEST Hybrid books',
	type: 'test',
    production: true,
    backendApiUrl: 'https://test-hybridbooks.arbookslibrary.com/api/',
    whitelistedDomains: ['test-hybridbooks.arbookslibrary.com'],
    blacklistedRoutes: ['test-hybridbooks.arbookslibrary.com/admin/'],
    backendApiVersion: 'v1',

};

