import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {LanguageService} from './services/language.service';
import { WebService } from './services/web.service';
import { SystemtextService } from './services/systemtext.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent  {
    title = 'frontend';

    constructor(private ws :WebService,private sts: SystemtextService, private httpClient: HttpClient, private ls: LanguageService) {
        this.ws.webDatas$.subscribe(items => {
            sessionStorage.setItem('webdatas', JSON.stringify(items.texts));
            this.sts.st = items.texts;
        }, error => {
            // console.log(error);
            var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
            //this.messageService.sendMessage({text: text, type: 'danger'});
        });
       
        this.ws.setWebDatas();
        if (!localStorage.getItem('lang')) {
            ls.getLanguageCodes().subscribe((codes) => {
                var browserLang = navigator.language;
                var subBrowserLang = browserLang.substr(0, 2);
                if (codes.indexOf(subBrowserLang) > -1) {
                    localStorage.setItem('lang', subBrowserLang);
                } else {
                    localStorage.setItem('lang', 'en');
                }
            });
        }
    }
   
   
}
