import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {WebService} from './services/web.service';
import {SystemtextService} from './services/systemtext.service';
import {FlagsService} from './services/flags.service';

@Component({
    selector: 'app-web',
    templateUrl: './web.component.html',
    styleUrls: ['./web.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WebComponent implements OnInit {

    isDataLoaded = false;
    isFlagsLoaded = false;

    constructor(private ws: WebService, private flagsService:FlagsService) {
    }

    ngOnInit() {
        this.ws.webDatas$.subscribe(items => {
            this.isDataLoaded = true;
        });
         if (!sessionStorage.getItem('webdatas')) {
            this.ws.setWebDatas();
        }
        if (!sessionStorage.getItem('flags')) {
            let flags = this.flagsService.getFlagsInfo();
            sessionStorage.setItem('flags', JSON.stringify(flags));
            this.isFlagsLoaded = true;
        }else{
            this.isFlagsLoaded = true;
        }

    }

}
