<div class="form-group text-left" [formGroup]="parentFormGroup">
  <label class="col-lg-12 control-label" *ngIf="title"
    >{{ title }}
    <app-tooltip
      *ngIf="toolTipOptions && toolTipOptions.text != ''"
      [toolTipOptions]="toolTipOptions"
    ></app-tooltip>
  </label>
  <br />
  <div formArrayName="{{ formArrayName }}" class="position-relative">
    <div *ngIf="actionInProgress || readonly" class="disable-all"></div>

    <div #container></div>

    <div class="ar-add">
      ({{ currentFileCount }}/{{ maxFileCount }})
      <div
        class="btn btn-success {{
          currentFileCount == maxFileCount ? 'disabled' : ''
        }}"
        (click)="addArRow('ar')"
      >
        <i class="fas fa-plus"></i> {{ addArBtnText }}
      </div>
      &nbsp;
      <div
        class="btn btn-success {{
          currentFileCount == maxFileCount ? 'disabled' : ''
        }}"
        (click)="addArRow('video')"
      >
        <i class="fas fa-plus"></i> {{ addVideoBtnText }}
      </div>
      &nbsp;
      <div
        class="btn btn-info {{
          currentFileCount == maxFileCount ? 'disabled' : ''
        }}"
        (click)="addArRow('group')"
      >
        <i class="fas fa-plus"></i>* Új csoport hozzáadása
      </div>
    </div>
  </div>
</div>

<ng-template #ar let-child="child" let-i="index">
  <div class="ar-block shadow" [formGroup]="child">
    <div class="row header">
      <div class="col">
        <div class="number">{{ i + 1 }}</div>
      </div>
      <div class="col">
        <div
          class="btn btn-danger btn-sm btn-delete"
          title="{{ deleteBtnText }}"
          (click)="removeArRow(child.value.uid)"
        >
          <i class="fas fa-trash-alt"></i>
        </div>
        <div
          class="btn btn-warning btn-sm btn-delete"
          title="Lokalizációs csomag törlése"
          (click)="removeLocalisation(child.controls.uid.value)"
        >
          <i class="fas fa-sync"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 title">
        <app-forminput
          [inputType]="'text'"
          [inputMinLength]="'3'"
          [control]="child.controls.title"
          [inputName]="'title'"
          [title]="'books.ar_meta_title'"
          [sts]="sts"
          [parentFormGroup]="child"
          [permissions]="permissions"
          [readonly]="readonly"
          [actionInProgress]="actionInProgress"
        ></app-forminput>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="arTargetImage"
          [control]="child.controls.arTargetImage"
          [title]="sts.c('books.ar_target_image')"
          maxFileSize="4"
          maxFileCount="1"
          accept="pngAndJpg"
          [acceptRestrictionMessage]="sts.c('validation.image_files_only')"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="arAndroid"
          [control]="child.controls.arAndroid"
          [title]="sts.c('books.ar_android')"
          maxFileSize="40"
          maxFileCount="1"
          accept="ar"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="arIos"
          [control]="child.controls.arIos"
          [title]="sts.c('books.ar_ios')"
          maxFileSize="40"
          maxFileCount="1"
          accept="ar"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="arWebgl"
          [control]="child.controls.arWebgl"
          [title]="sts.c('books.ar_webgl')"
          maxFileSize="40"
          maxFileCount="1"
          accept="ar"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-12">
        <app-forminput
          [inputType]="'text'"
          [inputMinLength]="'3'"
          [control]="child.controls.keywords"
          [inputName]="'keywords'"
          [title]="'books.ar_meta_keywords'"
          [sts]="sts"
          [parentFormGroup]="child"
          [permissions]="permissions"
          [toolTipOptions]="metaKeywordsTooltip"
          [readonly]="readonly"
          [actionInProgress]="actionInProgress"
        ></app-forminput>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #groupar let-child="child" let-i="uid" let-gi="groupUid">
  <div class="ar-block shadow" [formGroup]="child">
    <div class="row header">
      <div class="col">
      </div>
      <div class="col">
        <div
          class="btn btn-danger btn-sm btn-delete"
          title="{{ deleteBtnText }}"
          (click)="removeArRowFromGroup(gi,child.value.uid)"
        >
          <i class="fas fa-trash-alt"></i>
        </div>
        <div
          class="btn btn-warning btn-sm btn-delete"
          title="Lokalizációs csomag törlése"
          (click)="removeLocalisation(child.controls.uid.value)"
        >
          <i class="fas fa-sync"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 title">
        <app-forminput
          [inputType]="'text'"
          [inputMinLength]="'3'"
          [control]="child.controls.title"
          [inputName]="'title'"
          [title]="'books.ar_meta_title'"
          [sts]="sts"
          [parentFormGroup]="child"
          [permissions]="permissions"
          [readonly]="readonly"
          [actionInProgress]="actionInProgress"
        ></app-forminput>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="arCoverImage"
          [control]="child.controls.arCoverImage"
          [title]="sts.c('books.ar_cover_image')"
          maxFileSize="4"
          maxFileCount="1"
          accept="pngAndJpg"
          [acceptRestrictionMessage]="sts.c('validation.image_files_only')"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="arAndroid"
          [control]="child.controls.arAndroid"
          [title]="sts.c('books.ar_android')"
          maxFileSize="40"
          maxFileCount="1"
          accept="ar"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="arIos"
          [control]="child.controls.arIos"
          [title]="sts.c('books.ar_ios')"
          maxFileSize="40"
          maxFileCount="1"
          accept="ar"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="arWebgl"
          [control]="child.controls.arWebgl"
          [title]="sts.c('books.ar_webgl')"
          maxFileSize="40"
          maxFileCount="1"
          accept="ar"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-12">
        <app-forminput
          [inputType]="'text'"
          [inputMinLength]="'3'"
          [control]="child.controls.keywords"
          [inputName]="'keywords'"
          [title]="'books.ar_meta_keywords'"
          [sts]="sts"
          [parentFormGroup]="child"
          [permissions]="permissions"
          [toolTipOptions]="metaKeywordsTooltip"
          [readonly]="readonly"
          [actionInProgress]="actionInProgress"
        ></app-forminput>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #group let-child="child" let-i="index">
  <div class="ar-block shadow"  style="background-color: #17a2b8;" [formGroup]="child">
    <div class="row header">
      <div class="col">
        <div class="number">{{ i + 1 }}</div>
      </div>
      <div class="col">
        <div
          class="btn btn-danger btn-sm btn-delete"
          title="{{ deleteBtnText }}"
          (click)="removeArRow(child.value.uid)"
        >
          <i class="fas fa-trash-alt"></i>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between w-100 p-2"  style="background-color:var(--light)" >
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="groupTargetImage"
          [control]="child.controls.groupTargetImage"
          [title]="sts.c('books.ar_target_image')"
          [required]="true"
          maxFileSize="4"
          maxFileCount="1"
          accept="pngAndJpg"
          [acceptRestrictionMessage]="sts.c('validation.image_files_only')"
          parentComponent="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="d-flex flex-row align-items-end">
        <div
          class="btn btn-success {{
            currentFileCount == maxFileCount ? 'disabled' : ''
          }}"
          (click)="addArRowToGroup(child, 'gc_ar')"
        >
          <i class="fas fa-plus"></i> {{ addArBtnText }}
        </div>
        &nbsp;
        <div
          class="btn btn-success {{
            currentFileCount == maxFileCount ? 'disabled' : ''
          }}"
          (click)="addArRowToGroup(child, 'gc_video')"
        >
          <i class="fas fa-plus"></i> {{ addVideoBtnText }}
        </div>
      </div>

    </div>
    <div class="d-flex flex-column p-3">
      <div class="mb-2" *ngFor="let sub of child.controls.contents.value;">
        <ng-content *ngTemplateOutlet="getTemplate(sub.value.type);context:{child:sub,groupUid:child.value.uid,uid:sub.value.uid}"></ng-content>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #groupvideo let-child="child" let-i="uid" let-gi="groupUid">
  <div class="ar-block shadow" [formGroup]="child">
    <div class="row header">
      <div class="col">
      </div>
      <div class="col">
        <div
          class="btn btn-danger btn-sm btn-delete"
          title="{{ deleteBtnText }}"
          (click)="removeArRowFromGroup(gi,child.value.uid)"
        >
          <i class="fas fa-trash-alt"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 title">
        <app-forminput
          [inputType]="'text'"
          [inputMinLength]="'3'"
          [control]="child.controls.title"
          [inputName]="'title'"
          [title]="'books.ar_meta_title'"
          [sts]="sts"
          [parentFormGroup]="child"
          [permissions]="permissions"
          [readonly]="readonly"
          [actionInProgress]="actionInProgress"
        ></app-forminput>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="videoCoverImage"
          [control]="child.controls.videoCoverImage"
          [title]="sts.c('books.ar_cover_image')"
          maxFileSize="4"
          maxFileCount="1"
          accept="pngAndJpg"
          [acceptRestrictionMessage]="sts.c('validation.image_files_only')"
          [parentComponent]="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="video"
          [control]="child.controls.video"
          [title]="sts.c('books.video')"
          maxFileSize="40"
          maxFileCount="1"
          accept="video"
          [parentComponent]="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
    </div>
    <div class="col-lg-12">
      <app-forminput
        [inputType]="'text'"
        [inputMinLength]="'3'"
        [control]="child.controls.keywords"
        [inputName]="'keywords'"
        [title]="'books.ar_meta_keywords'"
        [sts]="sts"
        [parentFormGroup]="child"
        [permissions]="permissions"
        [toolTipOptions]="metaKeywordsTooltip"
        [readonly]="readonly"
        [actionInProgress]="actionInProgress"
      ></app-forminput>
    </div>
  </div>
</ng-template>

<ng-template #video let-child="child" let-i="index">
  <div class="ar-block shadow" [formGroup]="child">
    <div class="row header">
      <div class="col">
        <div class="number">{{ i + 1 }}</div>
      </div>
      <div class="col">
        <div
          class="btn btn-danger btn-sm btn-delete"
          title="{{ deleteBtnText }}"
          (click)="removeArRow(child.value.uid)"
        >
          <i class="fas fa-trash-alt"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 title">
        <app-forminput
          [inputType]="'text'"
          [inputMinLength]="'3'"
          [control]="child.controls.title"
          [inputName]="'title'"
          [title]="'books.ar_meta_title'"
          [sts]="sts"
          [parentFormGroup]="child"
          [permissions]="permissions"
          [readonly]="readonly"
          [actionInProgress]="actionInProgress"
        ></app-forminput>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="videoTargetImage"
          [control]="child.controls.videoTargetImage"
          [title]="sts.c('books.video_target_image')"
          maxFileSize="4"
          maxFileCount="1"
          accept="pngAndJpg"
          [acceptRestrictionMessage]="sts.c('validation.image_files_only')"
          [parentComponent]="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
      <div class="col-lg-3">
        <app-formfileupload
          [parentFormGroup]="parentFormGroup"
          formControlName="video"
          [control]="child.controls.video"
          [title]="sts.c('books.video')"
          maxFileSize="40"
          maxFileCount="1"
          accept="video"
          [parentComponent]="bookar"
          (sendDestroyRequest)="destroy(i)"
        ></app-formfileupload>
      </div>
    </div>
    <div class="col-lg-12">
      <app-forminput
        [inputType]="'text'"
        [inputMinLength]="'3'"
        [control]="child.controls.keywords"
        [inputName]="'keywords'"
        [title]="'books.ar_meta_keywords'"
        [sts]="sts"
        [parentFormGroup]="child"
        [permissions]="permissions"
        [toolTipOptions]="metaKeywordsTooltip"
        [readonly]="readonly"
        [actionInProgress]="actionInProgress"
      ></app-forminput>
    </div>
  </div>
</ng-template>
