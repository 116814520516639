import { Component, OnInit,Input,Output, ElementRef,EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fileselect',
  templateUrl: './fileselect.component.html',
  styleUrls: ['./fileselect.component.scss']
})
export class FileselectComponent implements OnInit {
  /**
   * File extention for the file selector. Must be ',' separated string, eg.: '.jpg ,.png'
   */
  @Input() acceptType :string;
  @Input() multiple :boolean = false;
  @Input() disabled :boolean = false;
  /**
   * Placeholder text for the file selector.
   */
  @Input() placeholder :string;
  @ViewChild('fileInput') fileInput: ElementRef;
  /**
   * Event that passes a single file.Can be used for a single fileselect.
   */
  @Output() setFile  = new EventEmitter<EventTarget>();
  /**
   * Event that passes the file array.Can be used to select multiple files.
   */
  @Output() setFiles  = new EventEmitter<EventTarget>();
  @Output() clearFile  = new EventEmitter();
  filename : any = null;
  constructor() { }

  ngOnInit() {
  }
  change($event){
    let files = $event.target.files;
      if (files[0]) {
        if(this.multiple){
          this.filename = files[0].name;
          this.readFiles(files);
        }else{
          this.filename = files[0].name;
          this.readFile(files[0]);
        }
      } else {
        this.clearFile.emit();
        this.delFile();
        this.filename = null;
      }
  }
  selectFile(){
    this.fileInput.nativeElement.click();
  }
  delFile(){
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.files = null;
    this.filename = null;
    this.clearFile.emit();
  }
  readFile(file){
    this.setFile.emit(file);
  }
  readFiles(files){
    this.setFiles.emit(files);
  }
}
