import {Component, Input, OnInit, ChangeDetectorRef, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { FormGroup, FormArray} from '@angular/forms';
import {BookarfilesService} from '../../../services/bookarfiles.service';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import { ArType, AR_FILE_TYPES } from 'src/app/admin/models/Book';

@Component({
    selector: 'app-formfileuploadbookar',
    templateUrl: './formfileuploadbookar.component.html',
    styleUrls: ['./formfileuploadbookar.component.scss'],
})
export class FormfileuploadbookarComponent implements OnInit {


    @Input() parentFormGroup: FormGroup;
    @Input() formType: string;
    @Input() formArrayName: string;
    @Input() removedArrayName: string;
    @Input() title: string;
    @Input() maxFileCount: number;
    @Input() sts;
    @Input() permissions;
    @Input() actionInProgress;
    @Input() readonly;
    @Input() metaKeywordsTooltip: any;

    currentFileCount: number;
    addArBtnText: string;
    addVideoBtnText: string;
    deleteBtnText: string;
    deleteMessage: string;
    @ViewChild('group',{static:true,read:TemplateRef}) group;
    @ViewChild('groupar',{static:true,read:TemplateRef}) groupar;
    @ViewChild('groupvideo',{static:true,read:TemplateRef}) groupvideo;
    @ViewChild('ar',{static:true,read:TemplateRef}) ar;
    @ViewChild('video',{static:true,read:TemplateRef}) video;
    @ViewChild('container',{read:ViewContainerRef }) vcr:ViewContainerRef;
    constructor(
      private cdref: ChangeDetectorRef,
      private bookarfilesService: BookarfilesService,
      private http: HttpClient) {
        // ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        // Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false'.
         cdref.detach();
    }

    ngOnInit() {
        // ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        // Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false'.

        this.addArBtnText = this.sts.c('books.ar_add');
        this.addVideoBtnText = this.sts.c('books.video_add');
        this.deleteBtnText = this.sts.c('books.ar_delete');
        this.deleteMessage = this.sts.c('books.ar_delete_confirm_message');
        this.cdref.detectChanges();
        this.cdref.reattach();
        this.populateView();

    }
    getTemplate(type:ArType){
      switch(type){
        case 'gc_ar': return this.groupar;
        case 'gc_video': return this.groupvideo;
      }

    }


    populateView() {
      if (this.currentFileCount == this.maxFileCount) return;
      // our formarray with coordinators
      // console.log('addArRow');
      const control = <FormArray>this.parentFormGroup.controls[this.formArrayName]["controls"];
      for (let i = 0; i < control.length; i++) {
        const element = control[i];
        const type = element.value.type;
        if (type=== 'ar') {
            this.vcr.createEmbeddedView(this.ar,{child:element,index:i});
        }
        if (type === 'video') {
            this.vcr.createEmbeddedView(this.video,{child:element,index:i});
        }
        if (type === 'group') {
          this.vcr.createEmbeddedView(this.group,{child:element,index:i});
        }
      }
      this.setCurrentFileCount();
      this.cdref.detectChanges();
    }

    addArRow(type:ArType) {
        if (this.currentFileCount == this.maxFileCount) return;
        // our formarray with coordinators
        // console.log('addArRow');
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        // we call function to push a new formgroup to the array
        if (type === 'ar') {
            const ctx = this.bookarfilesService.initBookAr()
            this.vcr.createEmbeddedView(this.ar,{child:ctx,index:control.length});
            control.push(ctx);
        }
        if (type === 'video') {
            const ctx = this.bookarfilesService.initBookVideo()
            this.vcr.createEmbeddedView(this.video,{child:ctx,index:control.length});
            control.push(ctx);
        }
        if (type === 'group') {
          const ctx = this.bookarfilesService.initBookGroup()
          this.vcr.createEmbeddedView(this.group,{child:ctx,index:control.length});
          control.push(ctx);
        }
        this.setCurrentFileCount();
        this.cdref.detectChanges();
    }


    addArRowToGroup(child:any,type:ArType){
      if (this.currentFileCount == this.maxFileCount) return;
      const control = child.controls['contents'].value;
      if (type === 'gc_ar') {
        control.push(this.bookarfilesService.initBookArGroup());
      }
      if (type === 'gc_video') {
          control.push(this.bookarfilesService.initBookVideoGroup());
      }
    }


    removeArRowFromGroup(groupUid:string|number,uid:any) {
      const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
      const group = control.controls.find(g=>g.value.uid == groupUid) as FormGroup;
      const content = group.value.contents.find((item=>item.value.uid == uid));
      if (this.contentControlFieldsIsTouched(content)) {
          if (confirm(this.deleteMessage)) {
              this.removeArRowFromGroupAfterConfirm(group, content);
          }
      } else {
          this.removeArRowFromGroupAfterConfirm(group, content);
      }

    }


    removeArRow(uid) {
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        const formGroup = control.controls.find(c=> c.value.uid == uid) as FormGroup;
        if (this.controlFieldsIsTouched(formGroup)) {
            if (confirm(this.deleteMessage)) {
                this.removeArRowAfterConfirm(formGroup);
            }
        } else {
            this.removeArRowAfterConfirm(formGroup);
        }

    }


    removeLocalisation(control_uid) {
      if (confirm("Biztos törlöd a lokalizációs csomagot?")) {
        this.removeLocalisationAfterConfirm(control_uid);
      }
    }


    removeLocalisationAfterConfirm(control_uid) {
      const url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
      this.http.get(url + '/books/removeLocalisation/'+control_uid).subscribe((response:any) => {
        if(response.success) {
          alert(response.message);
        }
        console.log("removeLocalisationAfterConfirm BE:",response);
      })
    }


    controlFieldsIsTouched(control:FormGroup) {

      if(control.controls.type.value === 'group') return true;

        if (
            control.controls.title.value && control.controls.title.value.length != 0 ||
            control.controls.keywords.value && control.controls.keywords.value.length != 0 ||

            (
                (control.controls.type.value === 'ar' &&
                    (
                        control.controls.arTargetImage.value.fileCount != 0 ||
                        control.controls.arAndroid.value.fileCount != 0 ||
                        control.controls.arIos.value.fileCount != 0 ||
                        control.controls.arWebgl.value.fileCount != 0
                    )
                )
                ||
                (control.controls.type.value === 'video' &&
                    (
                        control.controls.videoTargetImage.value.fileCount != 0 ||
                        control.controls.video.value.fileCount != 0
                    )
                )
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    contentControlFieldsIsTouched(control:FormGroup) {
          if (
              control.controls.title.value && control.controls.title.value.length != 0 ||
              control.controls.keywords.value && control.controls.keywords.value.length != 0 ||
  
              (
                  (control.controls.type.value === 'gc_ar' &&
                      (
                          control.controls.arCoverImage.value.fileCount != 0 ||
                          control.controls.arAndroid.value.fileCount != 0 ||
                          control.controls.arIos.value.fileCount != 0 ||
                          control.controls.arWebgl.value.fileCount != 0
                      )
                  )
                  ||
                  (control.controls.type.value === 'gc_video' &&
                      (
                          control.controls.videoCoverImage.value.fileCount != 0 ||
                          control.controls.video.value.fileCount != 0
                      )
                  )
              )
          ) {
              return true;
          } else {
              return false;
          }
    }

    
    removeArRowAfterConfirm(formGroup:FormGroup) {

        var block = formGroup.controls;
        var hasKeptFile = false;
        
        if( block.type.value === 'group'){
            this.removeAllRowsFromGroup(formGroup);
        }
        for (const key in block) {
            if (!AR_FILE_TYPES.includes(key)) continue;

            if (block[key].value.keptFiles.length > 0) {
                block[key].value.removedFiles = block[key].value.keptFiles;
                block[key].value.keptFiles = [];
                block[key].value.fileCount = 0;
                hasKeptFile = true;
            }
        }

        if (hasKeptFile) {
            const removedControl = <FormArray>this.parentFormGroup.controls[this.removedArrayName];
            removedControl.push(formGroup);
        }
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        const index = control.controls.indexOf(formGroup);
        control.removeAt(index);
        this.vcr.remove(index);
        this.setCurrentFileCount();
    }

    removeArRowFromGroupAfterConfirm(group:FormGroup, content:any) {
      const block = content.controls;
      var hasKeptFile = false;
      for (const key in block) {
          if (!AR_FILE_TYPES.includes(key)) continue;

          if (block[key].value.keptFiles.length > 0) {
              block[key].value.removedFiles = block[key].value.keptFiles;
              block[key].value.keptFiles = [];
              block[key].value.fileCount = 0;
              hasKeptFile = true;
          }
      }
      
      if (hasKeptFile) {
            const removedControl = <FormArray>this.parentFormGroup.controls[this.removedArrayName];

            removedControl.push(content);
      }
      const contents :any[] = group.value.contents;
      const contentIndex = contents.indexOf(content);
      contents.splice(contentIndex,1);
      this.setCurrentFileCount();
  }


  removeAllRowsFromGroup(group:FormGroup) {
    group.controls.contents.setValue([]) 
}

    setCurrentFileCount() {
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        this.currentFileCount = control.controls.length;
    }

    destroy(element) {
        if (this.formType == 'copy') {
            this.setNewUidIfKeeptIsEmpty(element);
        }
    }

    setNewUidIfKeeptIsEmpty(i) {
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        const block = control.controls[i]['controls'];

        // copy: If remove all stored files, and add new, the block keep the old uid and it's wrong and need to change.
        if (
            block['arAndroid'].value.keptFiles.length == 0 &&
            block['arIos'].value.keptFiles.length == 0 &&
            block['arTargetImage'].value.keptFiles.length == 0 &&
            block['arWebgl'].value.keptFiles.length == 0 &&
            block['arCoverImage'].value.keptFiles.length == 0 &&
            block['groupTargetImage'].value.keptFiles.length == 0 &&
            block['videoTargetImage'].value.keptFiles.length == 0 &&
            block['video'].value.keptFiles.length == 0
        ) {
            block['uid'].value = this.bookarfilesService.generateUid();
        }
    }

}
