import { Component, OnInit, Sanitizer, ViewChild, NgZone, AfterViewInit, ElementRef, ViewChildren } from '@angular/core';
import { TimelineComponent } from '../timeline/timeline.component';
import { LabelManagerComponent } from '../label-manager/label-manager.component';
import { Subscription, Observable } from 'rxjs';
import { TimelineService } from '../../services/timeline.service';
import { Publication } from '../../models/Publication';
import { AdmingridService } from '../../services/admingrid.service';
import { environment } from 'src/environments/environment';
import { map, tap, debounceTime } from 'rxjs/operators';
import { FileLoaderService } from '../../services/file-loader.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { FormcolorpickerComponent } from '../../layout/form/formcolorpicker/formcolorpicker.component';
import { SubSink } from 'subsink';
const path = '../../../assets/areditor/'
const UNITY_PLAYER_CONFIG = {
  dataUrl: path+"Build.data.unityweb",
  frameworkUrl: path+"Build.framework.js.unityweb",
  codeUrl: path+"Build.wasm.unityweb",
  streamingAssetsUrl: "StreamingAssets",
  companyName: "Multilearn",
  productName: "LibrARy WebGL Editor 2020",
  productVersion: "1"
};



@Component({
  selector: 'app-ar-editor',
  templateUrl: './ar-editor.component.html',
  styleUrls: ['./ar-editor.component.scss']
})
export class ArEditorComponent implements OnInit, AfterViewInit {
  subSink = new SubSink();
  st: any = null;
  gameInstance: any;
  publications: Publication[] = [];
  publication: Publication = null;
  publicationIndex: number = null;
  contentIndex: number = null;
  currentSlideIndex = 0;
  slideCount = 0;
  title: string;
  uid: number;
  currentTime = 0;
  maxTime = 0;
  subtitle = '';
  labelsId: number;
  /**
   * 0= stopped,1=paused,2=playing
   */
  isPlaying = 0;
  autoPlay = false;
  @ViewChild(TimelineComponent) timeline;
  @ViewChild(LabelManagerComponent) labManager;
  @ViewChildren(FormcolorpickerComponent) colorPickers;
  showSidenav = false;
  timeSubscription: Subscription;
  maxTimeSubscription: Subscription;
  playStateSubscription: Subscription;
  resetDurationSubscription: Subscription;
  setDurationSubscription: Subscription;
  saveSubscription: Subscription;
  showControls = false;
  showVolumeSlider = false;
  volume = 0.7;
  contentLoaded = false;
  editorLoaded = true;
  isLoading = false;
  saved = true;
  loadedContentId: string;
  contentId: string;
  flags: any;
  changeDelay = 200;
  settingsFg: FormGroup;
  fb: FormBuilder;
  sts: any;
  sidenavOut = true;
  zipCreationSub: Subscription;
  bookSubscription: Subscription;
  constructor(
     private _ngZone: NgZone,
     public sanitnizer: Sanitizer,
     private admingridService: AdmingridService,
     private timelineService: TimelineService,
     private fileLoaderService: FileLoaderService
     ) {
    window['arEditorComponentRef'] = {component: this, zone: _ngZone};
    this.fb = new FormBuilder();
   }
   c(s: string) {
    return s;
   }
  ngOnInit() {
    this.sts = {
      c : this.c
    };
    this.settingsFg = this.fb.group({
      topColor : new FormControl('', []),
      bottomColor : new FormControl('', []),
      size : new FormControl(1, []),
      offset : new FormControl(0, []),
      showGrid : new FormControl(true, []),
      gridColor : new FormControl(0, []),
      thickness : new FormControl(0.25, [])
    });
    this.settingsFg.controls['topColor'].setValue('#ee2d2d');
    this.isLoading = true;
    this.publications = [];
    this.flags = JSON.parse(sessionStorage.getItem('flags'));
    this.subSink.add(this.timelineService.saved.subscribe(saved => {
      this.saved = saved;
    }));
    this.subSink.add(this.fileLoaderService.uploadState.subscribe(state => {
      if (state === true) {
          if (this.publication != null) {
            this.zipCreationSub = this.fileLoaderService.CreateZip(this.publication.id).subscribe(result => {
              console.log('ZIP CREATED');
              this.timelineService.SetSaved(true, 'ZIP CREATION');
              this.GetPublications();
              this.zipCreationSub.unsubscribe();
            });
        }
      }
    }));
    this.GetPublications();
    this.subSink.add(this.fileLoaderService.downloadState.subscribe(ds => {
      if (ds === true) {
        // this.SetFirstSlideActive();
        this.isLoading = false;
      }
    }));
    this.subSink.add(this.timelineService.time.subscribe(t => {
      this.currentTime = t;
      if (this.gameInstance) {
        this.SetTime(t);
      }
    }));
    this.subSink.add(this.timelineService.maxTime.subscribe(m => {
      this.maxTime = m;

      if (this.autoPlay) {
        this.timeline.Play();
      }
    }));
    this.subSink.add(this.timelineService.resetDuration.subscribe(r => {
      if (this.gameInstance && this.contentLoaded && this.isPlaying != 2) {
        this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetOriginalDuration');
      }
    }));
    this.subSink.add(this.timelineService.duration.subscribe(duration => {
      console.log('Duration in angular', duration);

      if (this.gameInstance && this.contentLoaded && this.isPlaying != 2) {
        this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetFixedDuration', duration.toString());
      }
    }));
    this.subSink.add(this.timelineService.isPlaying.subscribe(playing => {
      this.isPlaying = playing;
    }));

    this.subSink.add(this.timelineService.nextSlide.subscribe(n => {
      if (n) {
        this.Next();
      }
    }));

    //#region EDITOR SETTINGS SUBSCRIPTIONS

    this.subSink.add(this.settingsFg.controls['topColor'].valueChanges
    .pipe(debounceTime(this.changeDelay)).subscribe(val => {
      this.SetTopColor(val.toString());
    }));
    this.subSink.add(this.settingsFg.controls['bottomColor'].valueChanges
    .pipe(debounceTime(this.changeDelay)).subscribe(val => {
      this.SetBottomColor(val.toString());
    }));
    this.subSink.add(this.settingsFg.controls['size'].valueChanges
    .pipe(debounceTime(this.changeDelay)).subscribe(val => {
      this.SetSize(val.toString());
    }));
    this.subSink.add(this.settingsFg.controls['offset'].valueChanges
    .pipe(debounceTime(this.changeDelay)).subscribe(val => {
      this.SetOffset(val.toString());
    }));
    this.subSink.add(this.settingsFg.controls['showGrid'].valueChanges
    .pipe(debounceTime(this.changeDelay)).subscribe(val => {
      this.SetShowGrid(val.toString());
    }));
    this.subSink.add(this.settingsFg.controls['gridColor'].valueChanges
    .pipe(debounceTime(this.changeDelay)).subscribe(val => {
      this.SetGridColor(val.toString());
    }));
    this.subSink.add(this.settingsFg.controls['thickness'].valueChanges
    .pipe(debounceTime(this.changeDelay)).subscribe(val => {
      this.SetThickness(val.toString());
    }));

    //#endregion

  }
   GetPublications() {
    var filterModel= {"b.maintenance":{"type":"set","filter":[{"id":true,"itemName":"Igen"}],"filterType":"set"}};
    this.bookSubscription = this.admingridService.getItems('books', 0, 0, [], '', filterModel)
    .pipe(tap(bookcall => this.st = bookcall.st.ar_editor ), map(books => books.items))
    .subscribe(async b => {
      this.isLoading = false;
      const pubs  = this.GetAllBooks(b);
      pubs.then(publications => {
      this.publications = publications.map(data => data.item);
       this.publications.forEach(pub => pub.lang_code = pub.language.replace('languages.', ''));
       setTimeout(() => {
        this.sidenavOut = false;
        console.log(this.publications);
        this.bookSubscription.unsubscribe();
      }, 500);
      }).catch(err => {
        console.log(err);
      }) ;
    });
  }
  async GetAllBooks(books: any[]) {
    const promiseArray = new Array(books.length);
    let i = 0;
    for await (const book of books) {
      const a = this.GetBook(book.id);
      promiseArray[i] = a;
      i++;
    }
    const files = await Promise.all(promiseArray);
    return files;
  }
  async GetBook(id: any) {
    return this.admingridService.getItem('books', id).toPromise();
  }
  //#region EDITOR SETTINGS FUNCTIONS

  SetTopColor(val: string) {
    if (this.gameInstance == null) {return; }
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetTopColor', val);
  }
  SetBottomColor(val: string) {
    if (this.gameInstance == null) {return; }
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetBottomColor', val);
  }
  SetSize(val: string) {
    if (this.gameInstance == null) {return; }
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetSize', val);
  }
  SetOffset(val: string) {
    if (this.gameInstance == null) {return; }
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetOffset', val);
  }
  SetShowGrid(val: boolean) {
    if (this.gameInstance == null) {return; }
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'ShowGrid', val);
  }
  SetGridColor(val: string) {
    if (this.gameInstance == null) {return; }
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetGridColor', val);
  }
  SetThickness(val: string) {
    if (this.gameInstance == null) {return; }
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetThickness', val);
  }

  //#endregion

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.saved) {
       return window.confirm('Discard unsaved changes?');
    }
    return true;
 }
  ngAfterViewInit() {
    window['timelineComponentRef'] = {component: this.timeline, zone: this._ngZone};
    window['labManagerComponentRef'] = {component: this.labManager, zone: this._ngZone};
  }
  ngOnDestroy(): void {
    window['arEditorComponentRef'] = null;
    window['timelineComponentRef'] = null;
    window['labManagerComponentRef'] = null;
    this.subSink.unsubscribe();
  }
  OpenSettings() {
    this.showSidenav = true;
  }
  CloseSettings() {
    this.showSidenav = false;
  }
  SaveSettings() {
    const settings = {
      topColor : this.settingsFg.controls['topColor'].value,
      bottomColor : this.settingsFg.controls['bottomColor'].value,
      size : this.settingsFg.controls['size'].value,
      offset : this.settingsFg.controls['offset'].value,
      showGrid : this.settingsFg.controls['showGrid'].value,
      gridColor : this.settingsFg.controls['gridColor'].value,
      thickness : this.settingsFg.controls['thickness'].value
    };
    localStorage.setItem('arEditorSettings', JSON.stringify(settings));
    this.showSidenav = false;
  }
  GetSettings() {
    const settings = localStorage.getItem('arEditorSettings');
    return settings ? JSON.parse(settings) : null;
  }
  GetSettingsFromEditor() {
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'GetSettings');
  }
  onPublicationSelected($event) {
    this.publicationIndex = $event;
    this.publication = this.publications[this.publicationIndex];

  }
  LoadEditor() {
    this.isLoading = true;
    this.editorLoaded = false;
    window["createUnityInstance"](document.querySelector('#gameContainer'),UNITY_PLAYER_CONFIG).then((unityInstance)=>{
      this.gameInstance = unityInstance;
    }).catch(err=>{
      console.error(err);
    });
  }

  async Save() {
        this.isLoading = true;
        const lang_code = this.publication.lang_code;
        this.fileLoaderService.IsLabelUploaded(false);
        this.fileLoaderService.IsAudioUploaded(false);
        this.fileLoaderService.IsLabelUploaded(false);
        this.fileLoaderService.IsConfigUploaded(false);
        await this.labManager.SaveLabels(this.publication.id, this.contentId, this.uid);
        await this.timeline.SaveAudioConfig(this.publication.id, this.contentId, this.uid);
        await this.timeline.SaveAudioFiles(this.publication.id, this.contentId, this.uid);
        await this.timeline.SaveSubtitleFiles(this.publication.id, this.contentId, this.uid);
    }

  Load(id: number, contentUid: string, contentIndex: number) {
    if (this.loadedContentId != contentUid) {
      this.isLoading = true;
      this.contentIndex = contentIndex;
      console.log(this.contentIndex);
      this.timelineService.SetContentIndex(contentIndex);
      this.contentId = contentUid ;
      this.contentLoaded = false;
      if (this.publication.ar_files_grouped[this.contentIndex].jsons &&
        this.publication.ar_files_grouped[this.contentIndex].jsons['labels']) {
        this.labelsId = this.publication.ar_files_grouped[this.contentIndex].jsons['labels'].id;
      } else {
        this.labelsId = null;
      }
     this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetBaseUrl', environment.backendApiUrl);
     this.SetToken(id);
    }
  }
  SetToken(id: number) {
    const token = sessionStorage.getItem('token');
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetToken', token);
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'LoadBundle', id.toString());
    this.contentLoaded = false;
}
ResetCamera() {
  this.gameInstance.SendMessage('CAM', 'ResetCamera');
}
  ChangeLabelText(value: string) {
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'ChangeLabelText', value);
  }
  SetVolume() {
    this.timelineService.SetVolume(this.volume);
  }
  MuteVolume() {
    this.volume = 0;
    this.timelineService.SetVolume(this.volume);
  }
  ToggleAutoplay() {
    const auto = this.autoPlay ? 'true' : 'false';
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'ToggleAutoplay', auto);
  }
  SetFirstSlideActive() {
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetFirstSlideActive');
  }
  Play() {
    console.log('---------Called Play');
    this.timelineService.Play();
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'PlaySlide');
  }
  Pause() {
    this.timelineService.Pause();
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'PauseSlide');
  }
  Stop() {
    this.timelineService.Stop();
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'StopSlide');
  }
  Next() {
    if (this.autoPlay) {
      console.log('---------Called Next');

      this.Play();
    } else {
      this.Stop();
    }
    this.timelineService.SetTime(0, 'From Next Slide Button');
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'NextSlide');
  }
  Prev() {
    if (this.autoPlay && this.currentSlideIndex != 1) {
      console.log('---------Called Prev');
      this.Play();
    } else {
      this.timelineService.SetMaxTime(this.maxTime);
      this.Stop();
    }
    this.timelineService.SetTime(0, 'From Prev Slide Button');
    this.gameInstance.SendMessage('CONTENT_MANAGER', 'PrevSlide');
  }
  SetTime(time: number) {
    if (this.isPlaying != 2) {
      this.gameInstance.SendMessage('CONTENT_MANAGER', 'SetTime', time);
    }
  }

  /////////////////////////////
  /// * CALLED FROM UNITY *///
  ///////////////////////////

  SetPlayingTime(time: number) {
   this.timelineService.SetTime(time, 'Time From Unity');
  }
  /// Change to Set Content Info In unity webgl

  GetContentInfo(currentSlideIndex: number, slideCount: number, title: string, uid: number) {
    console.log('Index: ', currentSlideIndex, 'Slides: ', slideCount, 'Title: ', title);
    this.currentSlideIndex = currentSlideIndex + 1;
    this.slideCount = slideCount;
    this.title = title;
    this.uid = uid;

  }
  SetDefaultSettings() {
    this.settingsFg.controls['topColor'].setValue('#00ff00');
    this.settingsFg.controls['bottomColor'].setValue('#0000ff');
    this.settingsFg.controls['size'].setValue(1);
    this.settingsFg.controls['offset'].setValue(0);
    this.settingsFg.controls['showGrid'].setValue(true);
    this.settingsFg.controls['gridColor'].setValue('#ffffff');
    this.settingsFg.controls['thickness'].setValue(0.25);
    // this.SaveSettings();
    this.UpdateColors();
  }
  UpdateColors() {
    this.colorPickers.forEach(element => {
      element.setColor();
    });
  }
  SetSettings(json: string) {
    console.log('Settings From Unity', json);
    const settings = JSON.parse(json);
    console.log('Top Color: ', settings.topColor);
    console.log(this.settingsFg);
    this.settingsFg.controls['topColor'].setValue('#' + settings.topColor);
    this.settingsFg.controls['bottomColor'].setValue('#' + settings.bottomColor);
    this.settingsFg.controls['size'].setValue(settings.size);
    this.settingsFg.controls['offset'].setValue(settings.offset);
    this.settingsFg.controls['showGrid'].setValue(settings.showGrid);
    this.settingsFg.controls['gridColor'].setValue('#' + settings.gridColor);
    this.settingsFg.controls['thickness'].setValue(1);
    this.UpdateColors();
    this.SaveSettings();
  }

    OnAssetsLoaded(slideCount: number) {
      this.contentLoaded = true;
      this.loadedContentId = this.contentId;
      console.log('Loaded Content ID : ', this.loadedContentId);
      this.showControls = true;
    }
    EditorLoaded() {
      console.log('EDITOR IS LOADED SUCCESSFULLY!');
      this.editorLoaded = true;
      this.isLoading = false;
      const settings = this.GetSettings();
      if (settings == null) {
        this.GetSettingsFromEditor();
      } else {
        this.GetSettingsFromLocalStorage(settings);
      }
    }
    GetSettingsFromLocalStorage(settings: any) {
      this.settingsFg.controls['topColor'].setValue(settings.topColor);
      this.settingsFg.controls['bottomColor'].setValue(settings.bottomColor);
      this.settingsFg.controls['size'].setValue(settings.size);
      this.settingsFg.controls['offset'].setValue(settings.offset);
      this.settingsFg.controls['showGrid'].setValue(settings.showGrid);
      this.settingsFg.controls['gridColor'].setValue(settings.gridColor);
      this.settingsFg.controls['thickness'].setValue(settings.thickness);
      this.UpdateColors();
    }

}
