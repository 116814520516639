import {Component, OnInit} from '@angular/core';
import {AdmingridComponent} from '../../controllers/admingrid/admingrid.component';
import {DropdownFilter} from '../../controllers/admingrid/dropdownfilter.component';

@Component({
  selector: 'app-books',
  templateUrl: '../grid.base.template.html',
  styleUrls: ['../grid.base.template.scss']
})
export class BooksComponent  extends AdmingridComponent implements OnInit{

    colDef_id = {headerName: 'Id', field: 'id', colId: 'b.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter'};
    colDef_uid = {headerName: '', field: 'uid', colId: 'b.uid', sortable: true, resizable: true, show: false, filter: 'agNumberColumnFilter'};
    colDef_name = {headerName: '', field: 'name', colId: 'b.name', sortable: true, resizable: true, alwaysShow: true, show: true, filter: 'agTextColumnFilter'};
    colDef_key = {headerName: '', field: 'key', colId: 'b.key', sortable: true, resizable: true, alwaysShow: true, show: true, filter: 'agTextColumnFilter'};
    colDef_description = {headerName: '', field: 'description', colId: 'b.description', sortable: true, resizable: true,  show: false, filter: 'agTextColumnFilter'};
    colDef_short_description = {headerName: '', field: 'short_description', colId: 'b.short_description', sortable: true, resizable: true,  show: false, filter: 'agTextColumnFilter'};
    colDef_language = {headerName: '', field: 'language', colId:'b.language', sortable: true, resizable: true, show: true, filter: 'DropdownFilter', cellRenderer:this.languageRenderer};
    colDef_category = {headerName: '', field: 'category', colId:'b.category', sortable: true, resizable: true, show: true, filter: 'DropdownFilter'};
    colDef_organization_name = {headerName: '', field: 'organization_name', colId: 'organizations.name', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter'};
    colDef_marketplace = {headerName: '', field: 'marketplace', colId: 'b.marketplace', sortable: true, resizable: true, show: true, filterLangPrefix:'grid.', filterSingleSelection:true, filter: 'DropdownFilter', cellRenderer: this.checkboxRenderer};
    colDef_published = {headerName: '', field: 'published', colId: 'b.published', sortable: true, resizable: true, show: true, filterLangPrefix:'grid.', filterSingleSelection:true, filter: 'DropdownFilter', cellRenderer: this.checkboxRenderer};
    colDef_ar_camera_content = {headerName: '', field: 'ar_camera_content', colId: 'b.ar_camera_content', sortable: true, resizable: true, show: true, filterLangPrefix:'grid.', filterSingleSelection:true, filter: 'DropdownFilter', cellRenderer: this.checkboxRenderer};
    colDef_shopping_id = {headerName: '', field: 'shopping_id', colId: 'b.is_shopping', sortable: true, resizable: true, show: true, filterLangPrefix:'grid.', filterSingleSelection:true, filter: 'DropdownFilter', cellRenderer: this.checkboxRenderer};
    colDef_maintenance = {headerName: '', field: 'maintenance', colId: 'b.maintenance', sortable: true, resizable: true, show:true, filterLangPrefix:'grid.', filter: 'DropdownFilter',  cellRenderer: this.maintenanceRenderer};

    columnDefs = [this.colDef_id, this.colDef_uid, this.colDef_name, this.colDef_key, this.colDef_description, this.colDef_short_description, this.colDef_language, this.colDef_category, this.colDef_organization_name,this.colDef_ar_camera_content,this.colDef_marketplace, this.colDef_published, this.colDef_shopping_id, this.colDef_maintenance ];

    firstLoadDone:boolean = false;


    ngOnInit() {
        super.ngOnInit();

        this.frameworkComponents = { DropdownFilter: DropdownFilter };

        this.tableName = 'books';
        this.formName = 'Books';
        this.setPermissions(this.tableName);
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;


        this.messageService.getStatus().subscribe(
            message => {
                if (message) {
                    if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {

                        this.sts.replace('grid.add_btn', [{'find': ':item', 'replace': 'books.book'}]);
                        this.sts.replace('grid.show_btn', [{'find': ':item', 'replace': 'books.book'}]);
                        this.sts.replace('grid.modify_btn', [{'find': ':item', 'replace': 'books.book'}]);
                        this.sts.replace('grid.copy_btn', [{'find': ':item', 'replace': 'books.book'}]);
                        this.sts.replace('grid.maintenance_btn', [{'find': ':item', 'replace': 'books.book'}]);
                        this.sts.replace('grid.webgl_zip_btn', [{'find': ':item', 'replace': 'books.book'}]);
                        this.sts.replace('grid.delete_btn', [{'find': ':item', 'replace': 'books.book'}]);

                        this.setColNameByLang(this.colDef_uid, 'books.uid', message.gridOptions);
                        this.setColNameByLang(this.colDef_name, 'books.name', message.gridOptions);
                        this.setColNameByLang(this.colDef_key, 'books.key', message.gridOptions);
                        this.setColNameByLang(this.colDef_description, 'books.description', message.gridOptions);
                        this.setColNameByLang(this.colDef_short_description, 'books.short_description', message.gridOptions);
                        this.setColNameByLang(this.colDef_language, 'books.language', message.gridOptions);
                        this.setColNameByLang(this.colDef_category, 'books.category', message.gridOptions);
                        this.setColNameByLang(this.colDef_organization_name, 'orgs.organization', message.gridOptions);
                        this.setColNameByLang(this.colDef_marketplace, 'books.marketplace', message.gridOptions);
                        this.setColNameByLang(this.colDef_published, 'books.published', message.gridOptions);
                        this.setColNameByLang(this.colDef_ar_camera_content, 'books.ar_camera_content', message.gridOptions);
                        this.setColNameByLang(this.colDef_shopping_id, 'books.shopped', message.gridOptions);
                        this.setColNameByLang(this.colDef_maintenance, 'books.maintenance', message.gridOptions);

                        message.gridOptions.api.refreshHeader();
                        this.onColumnsChanged();

                        this.firstLoadDone = true;

                    }
                }
            }
        );

    }


    changeValueByLang(items) {
        items.forEach((item) => {
            item.language = this.sts.c(item.language)+'¤'+item.language;
            item.category = this.sts.c(item.category);
            item.maintenance_orig = item.maintenance;
            item.maintenance = item.maintenance == '1' ? this.sts.c('grid.true') : this.sts.c('grid.false');
        });
    }


    actionBySelectedRowsChangeBeforeExecute(propObj) {
        // If has shopping_id, can't edit just show.
        const selectedData = propObj.selectedNodes.map(node => node.data);
        const shopping_id = selectedData.map(node => node.shopping_id);
        if(shopping_id[0] && propObj.type == "edit") propObj.type = "show";
    }




}
