import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagenotfound',
  template: `
  <div class="not-found-wrapper">
       <h1 style="text-align: center;font-size: 4em;">404</h1>
       <h4 style="text-align: center;">Page Not Found</h4>
       <button class="btn btn-primary" style="margin:auto;display:block" (click)="ReloadHome()" >Home</button>
</div>
  `,
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent {

  constructor() { }

  ReloadHome(){
    window.location.replace('/')
  }

}
