import {Injector} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {PermissionsService} from '../../services/permissions.service';
import {MessageService} from '../../services/message.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {AdmingridService} from '../../services/admingrid.service';
import {ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import {SystemtextService} from '../../../services/systemtext.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {isObject} from 'util';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';


type submitFnType = (tableName: string, formValue: {}) => Observable<any>;

@Component({
    selector: 'app-adminform',
    templateUrl: './adminform.component.html',
    styleUrls: ['./adminform.component.scss']
})
export class AdminformComponent implements OnInit {

    public permissions;
    public tableName: string;
    public formName: string;
    public actionInProgress: boolean = false;
    public readonly : boolean = false;
    public formReadyToShow: boolean = false;
    public higherRoleIsNeeded: boolean = false;
    public formType: string;
    public localeText = JSON.parse(sessionStorage.getItem('basetexts')).form[localStorage.getItem('lang')];

    public toolTipOptions = {
        'placement': 'top',
        'theme': 'light',
        'text': '',
    };

    public fileUploadOptions = {
        'hasFiles': false,
        'text': '',
        'type': '',
        'canCancel': false,
        'cancelText': ''
    };

    public customRedirect: boolean|string = false;

    protected needToUploadFileKeys = [];
    protected needToUploadFileOrigLength: number = 0;
    protected needToUploadFileSuccessLength: number = 0;
    protected needToUploadFileCanceled: boolean = false;
    protected uploadFilesSubscription: any;
    protected uploadFilesUid: string = '';

    mainForm: FormGroup;

    protected fb;
    protected admingridService;
    protected router;
    protected activatedRoute;
    protected messageService;
    protected permissionsService;
    protected sts;
    protected http;

    constructor(injector: Injector) {
        this.fb = injector.get(FormBuilder);
        this.admingridService = injector.get(AdmingridService);
        this.router = injector.get(Router);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.messageService = injector.get(MessageService);
        this.permissionsService = injector.get(PermissionsService);
        this.sts = injector.get(SystemtextService);
        this.http = injector.get(HttpClient);

        this.messageService.clearMessage();
        this.formType = this.activatedRoute.snapshot.url[0].path === 'edit' ? 'update' : this.activatedRoute.snapshot.url[0].path;
    }

    ngOnInit() {

    }

    setPermissions(tableName) {
        this.permissions = this.permissionsService.getPermissions(tableName);
    }

    passwordValidator(type: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            return (
                (control.value.length < 6 && (type === 'create' || type === 'copy')) ||
                (control.value.length > 0 && control.value.length < 6 && type === 'update')) ?
                {minlength: true} : null;
        };
    }

    isValidEmail(control: FormControl) {
        let email_regexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        return email_regexp.test(control.value) ? null : {invalidEmail: true};
    }

    samePassword(group: FormGroup) {
        const password = group.get('password').value;
        const password_confirmation = group.get('password_confirmation').value;
        return password === password_confirmation ? null : {invalidPassword: true};
    }

    replaceSystemTitle(replaceName: string, find = ':item') {
        this.sts.replace('form.add_new_title', [{'find': find, 'replace': replaceName}]);
        this.sts.replace('form.update_title', [{'find': find, 'replace': replaceName}]);
        this.sts.replace('form.copy_title', [{'find': find, 'replace': replaceName}]);
        this.sts.replace('form.show_title', [{'find': find, 'replace': replaceName}]);
    }


    createOrUpdate() {
        if (!this.actionInProgress) {
            this.actionInProgress = true;
            // console.log('this.formType:', this.formType);
            if (this.formType === 'create' || this.formType === 'copy') this.createItem();
            if (this.formType === 'update') this.updateItem();
        }

    }

    updateItem(formValue = null) {
        //console.log('**** update Form.value', formValue);
        //this.actionInProgress = false;
        this.submitForm('updateItem', formValue);
    }

    createItem(formValue = null) {
        // console.log('**** create this.mainForm.value', this.mainForm.value);
        this.submitForm('addItem', formValue);
    }

    /**
     * Submits the file data (if any) and the form value.
     * This is the main function for submitting a form.
     * @param submitFnName  The name of the admingridService submit funtion for submitting the form value.
     *                      Usually 'updateItem' or 'addItem'.
     * @param formValue     The form value object.
     */
    protected submitForm(submitFnName: string, formValue: {}) {
        let fv = formValue ? formValue : this.mainForm.value;

        this.transformGroupContents(fv);

        // book ar removed
        if (typeof(fv.bookArRemoved) != 'undefined' && fv.bookArRemoved.length > 0) {
            fv.bookArRemoved.forEach((removed) => {
                fv.bookAr.push(removed);
            });
            delete(fv.bookArRemoved);
        }

        if (this.formType) fv.formType = this.formType;

        let filedata = this.fileDataFromFormValue(fv);
        //console.log(filedata);
        // this.actionInProgress = false;
        // console.log('fv:', fv);
        // console.log('filedata:', filedata);
        // console.log('needToUploadFileKeys', this.needToUploadFileKeys);
        // console.log(...filedata);
        //return false; // debug

        if (this.needToUploadFileKeys.length > 0) {
            this._submitFormPreValidate(submitFnName, fv, filedata);
        } else {
            this.fileUploadOptions.hasFiles = false;
            this._submitFormValue(submitFnName, fv);
        }

    }

    protected _submitFormPreValidate(submitFnName: string, formValue: {}, filedata: FormData) {
        let ags = this.admingridService;
        formValue['preValidate'] = true;
        // console.log('formValue', formValue);
        // console.log('submitFnName', ags[submitFnName]);
        (<submitFnType>ags[submitFnName])(this.tableName, formValue).subscribe({
            next: result => {
                if (!result) {
                    this.showError({error: this.sts.c('form.e_error_sending_values')});
                } else if (result.message == 'Validate failed') {
                    this.showValidateFailed(result);
                }
                else {
                    this.refreshTokenBeforeFileUpload(filedata, formValue, submitFnName);
                }
            },
            error: error => this.showError(error)
        });
    }
    transformGroupContents(fv:any){
      for (let i = 0; i < fv['bookAr'].length; i++) {
        const element = fv['bookAr'][i];
        if(element.type === 'group'){
          const items = element.contents.map(content=> content.value);
          element.contents = items;
        }
      }
    }

    protected refreshTokenBeforeFileUpload(filedata: FormData, formValue: {}, submitFnName: string) {
        this.uploadFilesSubscription = this.admingridService.refreshToken().subscribe({
            next: result => {
                if (result === true) {
                    this.needToUploadFileOrigLength = this.needToUploadFileKeys.length;

                    this.fileUploadOptions.hasFiles = true;
                    this.fileUploadOptions.type = 'info';
                    this.fileUploadOptions.text = this.getFilesUploadInProgressStringWithCurrentValue();
                    this.fileUploadOptions.canCancel = true;
                    this.fileUploadOptions.cancelText = this.sts.c('form.files_upload_cancel');
                    const arFileKey = this.needToUploadFileKeys.shift();

                    this.sendFileOneByOne(arFileKey, filedata, formValue, submitFnName, {});
                }
            },
            error: error => {
                this.showError(error);
            }
        });
    }

    protected getFilesUploadInProgressStringWithCurrentValue() {
        let s = this.sts.c('form.files_upload_in_progress');
        let sr = s.replace(':all_counter', this.needToUploadFileOrigLength);
        let srr = sr.replace(':finished_counter', this.needToUploadFileSuccessLength);
        return srr;
    }

    public cancelUploadFiles() {
        this.fileUploadOptions.text = this.sts.c('form.files_upload_cancel_in_progress');
        this.fileUploadOptions.canCancel = false;
        this.uploadFilesSubscription.unsubscribe();
        if (this.uploadFilesUid) {
            console.log('Remove temp files: ', this.uploadFilesUid);
            this.uploadFilesSubscription = this.admingridService.deleteTempFiles(this.tableName, this.uploadFilesUid).subscribe({
                next: result => {
                    if (!result) {
                        this.showError({error: this.sts.c('form.e_error_sending_files')});
                    } else {
                        this.cancelUploadFileFinished();
                    }
                },
                error: error => {
                    this.showError(error);
                }
            });
        } else {
            this.cancelUploadFileFinished();
        }
    }

    protected cancelUploadFileFinished() {
        this.fileUploadOptions.hasFiles = false;
        this.uploadFilesUid = '';
        this.needToUploadFileSuccessLength = 0;
        this.needToUploadFileKeys = [];
        this.actionInProgress = false;
    }

    protected sendFileOneByOne(arFileKey, filedata: FormData, fv: {}, submitFnName: string, params: {}) {
        let file = filedata.get(arFileKey) as File;

        let oneFiledata = new FormData();
        oneFiledata.append(arFileKey, file, file['name']);

        this.uploadFilesSubscription = this.admingridService.uploadFiles(this.tableName, oneFiledata, fv['id'], params).subscribe({
            next: result => {
                if (!result) {
                    this.showError({error: this.sts.c('form.e_error_sending_files')});
                }
                else if (result.message == 'Validate failed') {
                    this.showValidateFailed(result);
                }
                else if (!result.uuid) {
                    this.showError({error: this.sts.c('form.e_error_sending_files')});
                }
                else {
                    //console.log("result",result);
                    this.uploadFilesUid = result.uuid;

                    if (this.needToUploadFileKeys.length > 0) {
                        this.needToUploadFileSuccessLength++;
                        this.fileUploadOptions.text = this.getFilesUploadInProgressStringWithCurrentValue();
                        const arFileKey = this.needToUploadFileKeys.shift();
                        const params = {
                            'uid': result.uuid,
                            'bookAr': JSON.stringify(result.bookAr),
                        };
                        //console.log("params",params);
                        this.sendFileOneByOne(arFileKey, filedata, fv, submitFnName, params);

                    } else {
                        this.fileUploadOptions.hasFiles = false;
                        fv['_file_data_uuid'] = result.uuid;
                        fv['_file_bookar_meta_uid'] = result.bookAr;
                        //console.log("fv end",fv);
                        fv['preValidate'] = '';
                        this._submitFormValue(submitFnName, fv);
                    }
                }
            },
            error: error => {
                this.showError(error);
            }
        });
    }

    protected fileDataFromFormValue(formValue: {}) {
        let filedata = new FormData();
        let hasFileData ={  value: false};
        for (const key in formValue) {
            if (formValue.hasOwnProperty(key)) {
                const value = formValue[key];

                if (key == 'bookAr') {
                    for (const bakey in value) {
                        if (value.hasOwnProperty(bakey)) {
                            const block = value[bakey];
                            for (const blockKey in block) {
                                if (block.hasOwnProperty(blockKey)) {
                                    const blockPropValue = block[blockKey];
                                    if(blockKey == "contents"){
                                      const contents = blockPropValue;
                                      contents.forEach(content => {
                                        for(const contKey in content){
                                          const v = content[contKey];
                                          const args ={
                                            content:content,
                                            parentUid:block.uid,
                                            propertyValue:v,
                                            filedata:filedata,
                                            key:key,
                                            propkey:contKey,
                                            hasFileData:hasFileData,
                                            groupContent:true
                                          }
                                          this.addFileDataToFormData(args);
                                        }
                                      });
                                    }else{
                                      const args ={
                                        content:block,
                                        propertyValue:blockPropValue,
                                        filedata:filedata,
                                        key:key,
                                        propkey:blockKey,
                                        hasFileData:hasFileData,
                                        groupContent:false
                                      }
                                      this.addFileDataToFormData(args);
                                  }

                                }
                            }
                        }
                    }

                } else {
                    if (value!== null && typeof value === 'object' && value['type'] === 'formfileupload') {
                        value['addedFiles'].forEach((file: File) => {
                            filedata.append(`${key}[]`, file, file.name);
                            this.needToUploadFileKeys.push(`${key}[]`);
                            hasFileData.value = true;
                        });
                    }
                }

            }
        }
        return hasFileData.value ? filedata : null;
    }
    addFileDataToFormData(args:any){
      let {content,propertyValue,filedata,key,propkey,hasFileData,groupContent} = args;
      let fd :FormData = filedata;
      const uid = content.uid;
      let isGroup = groupContent;
      if (isObject(propertyValue) && propertyValue['type'] === 'formfileupload') {
          propertyValue['addedFiles'].forEach((file: File) => {
            if(propkey =="groupTargetImage"){
                isGroup = true;
            }
            const formKey = isGroup?key+'Group':key;
            fd.append(`${formKey}-${propkey}-${uid}[]`, file, file.name);
              this.needToUploadFileKeys.push(`${formKey}-${propkey}-${uid}[]`);
              //console.log("filedata get by name : ",`${formKey}-${propkey}-${uid}[]`);
              hasFileData.value = true;
          });

      }
    }

    /**
     * Used internally by this.submitForm().
     * @param submitFnName  The name of the admingridService submit funtion for submitting the form value.
     *                      Usually 'updateItem' or 'addItem'.
     * @param formValue     The form value object.
     */
    protected _submitFormValue(submitFnName: string, formValue: {}) {
        let ags = this.admingridService;
        (<submitFnType>ags[submitFnName])(this.tableName, formValue).subscribe({
            next: result => {
                if (!result) {
                    this.showError({error: this.sts.c('form.e_error_sending_values')});
                } else if (result.message == 'Validate failed') {
                    this.showValidateFailed(result);
                }
                else {
                    const type = result.messageType ? result.messageType : 'success';
                    this.navigateToUrl(result.message, type);
                }
            },
            error: error => this.showError(error)
        });
    }

    protected getSubmitFn(submitFnName: string) {
        let submitFn = <submitFnType>this.admingridService[submitFnName];
        submitFn.bind(this.admingridService);
        return submitFn;
    }


    showError(error: any) {
        console.log(error);
        this.mainForm.setErrors({hasError: true});
        this.actionInProgress = false;
        var text = isObject(error['error']) && error.error.hasOwnProperty('message') ? error.error.message : error.message;

        this.fileUploadOptions.type = 'danger';
        if (text) {
            this.fileUploadOptions.text = text;
        } else {
            this.fileUploadOptions.hasFiles = false;
        }

        this.fileUploadOptions.canCancel = false;

        this.messageService.sendMessage({text: text, type: 'danger'});
    }

    navigateToUrl(message, messageType) {
        this.actionInProgress = false;
        const customRedirect = this.customRedirect ? this.customRedirect : '/admin/' + this.tableName + '/show';
        this.router.navigate([customRedirect], {
            queryParams: {message: message, messageType: messageType},
            state: {ignoreLoadingBar: true}
        });
    }

    showValidateFailed(items) {
        this.cancelUploadFileFinished();
        // console.log('this.mainForm.controls', this.mainForm);
        this.actionInProgress = false;
        this.mainForm.setErrors({hasError: true});
        // console.log('Show validate failed items: ', items);
        for (const key in items.validator) {
            const value = items.validator[key];

            /*
            var controlNames = key.split('.');
            var form = this.mainForm;
            controlNames.forEach(function (cName) {
                form = form.controls[cName];
            });
            */

            var form = this.mainForm.get(key);

            // If 1 controls: this.mainForm.controls[key].setErrors({validateFailed: value});
            // If more controls: this.mainForm.controls[controlNames[0]].controls[controlNames[1]].setErrors({validateFailed: value});
            form.setErrors({validateFailed: value});


        }
    }

}
