import {Component, OnInit, HostListener, ViewEncapsulation} from '@angular/core';
import {Location} from '@angular/common';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Router, ActivatedRoute} from '@angular/router';

import {MessageService} from './services/message.service';
import {AuthService} from '../services/auth.service';
import {FlagsService} from "../services/flags.service";
import { WebService } from '../services/web.service';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                // transform: 'translate3d(0, 0, 0)'
            })),
            state('out', style({
                //transform: 'translate3d(0,-150%, 0)',
                height: '0px',
                opacity: '0',
                marginTop: '-10px'
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ],
    encapsulation: ViewEncapsulation.None
})
export class AdminComponent implements OnInit {

    NavbarLeftOpen: boolean = true;
    innerWidth: any;
    message: string = null;
    messageType: string = '';
    messageState: string = 'out';
    menuOutTimeout: any;
    showLoginModal: boolean = false;
    onlymaincontent: boolean = false;
    webDataLoaded = false;

    constructor(private ws: WebService,private messageService: MessageService, private location: Location, private router: Router, private route: ActivatedRoute, private authService: AuthService,  private flagsService:FlagsService) {
        
        ws.webDatas$.subscribe(data=>{
            this.webDataLoaded = true;
        })
       
        this.route.queryParamMap.subscribe(params => {
            this.onlymaincontent = params.get('onlymaincontent') == 'true' ? true : false;
        });

        this.messageService.getMessage().subscribe(
            message => {
                //console.log('message:', message);
                if (message) {

                    if (message.tokenHasExpired) {
                        this.showLoginModal = true;
                    } else {
                        clearTimeout(this.menuOutTimeout);
                        this.message = message.text;
                        this.messageType = message.type;


                        setTimeout(function () {
                            this.messageState = 'in';
                            if (message.type === 'success') {
                                this.menuOutTimeout = setTimeout(function () {
                                    this.messageState = 'out';
                                    setTimeout(function () {
                                        this.message = null;
                                        // remove message from url
                                        this.location.replaceState(this.location.path().split('?')[0], '');
                                    }.bind(this), 1000);
                                }.bind(this), 5000);
                            }
                        }.bind(this), 1000);
                    }


                } else {
                    this.message = null;
                    this.messageType = 'default';
                }
            }
        );
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.setNavbarLeftOpensByInnerWidth();
        if (!sessionStorage.getItem('flags')) {
          let flags = this.flagsService.getFlagsInfo();
          sessionStorage.setItem('flags', JSON.stringify(flags));
        }
    }


    setNavbarLeftOpen(event) {
        // console.log("setNavbarLeftStatus:",event);
        this.NavbarLeftOpen = event;
    }

    setNavbarLeftOpensByInnerWidth() {
        if (this.innerWidth <= 991.98) this.NavbarLeftOpen = false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        this.setNavbarLeftOpensByInnerWidth();
    }


}
