import {Routes} from '@angular/router';
import {DesigntestComponent} from './pages/designtest/designtest.component';
import {RegisterComponent} from './pages/register/register.component';
import {LoginComponent} from './pages/login/login.component';
import {LogoutComponent} from './services/logout.component';
import {HomeComponent} from './pages/home/home.component';
import {PrototipusComponent} from "./pages/prototipus/prototipus.component";
import {GyartokornyezetComponent} from "./pages/gyartokornyezet/gyartokornyezet.component";
import {TechnologiaComponent} from "./pages/technologia/technologia.component";


export const webRoutes: Routes = [

    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'home', component: HomeComponent},
    // {path: 'designtest', component: DesigntestComponent},
    {path: 'prototipus-konyveink', component: PrototipusComponent},
    {path: 'gyartokornyezet', component: GyartokornyezetComponent},
    {path: 'technologia', component: TechnologiaComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent}

];
